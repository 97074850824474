<template>
  <v-hover v-slot:default="{ hover }" v-if="article">
    <v-card class="item-card" :elevation="hover ? 10 : 2">
      <div class="notpublicated" v-if="!article.publicated">Non publié</div>
      <v-img
        v-if="article.picture.indexOf('http') !== -1"
        :src="article.picture"
        link
        v-on:click="navigate('/feeds/'+article.url)"
        :data-id="index"
        :open-delay="delay"
        v-ripple
        :aspect-ratio="3/4"
        :style="'max-height:' + imageHeight"
      />
      <div
        link
        v-on:click="navigate('/feeds/'+article.url)"
        v-ripple
        :data-id="index"
        :open-delay="delay"
        :ratio="3/4"
        v-if="article.picture.indexOf('http') === -1"
        :style="'width:100%; height:56.25%; max-height:'+imageHeight+'; overflow:hidden; background-color:#e9ebf7;'"
      >
        <Alphabet :ratio="4/3" :message="article.picture"></Alphabet>
      </div>
      <div
        link
        v-on:click="navigate('/feeds/'+article.url)"
        :data-id="index"
        :open-delay="delay"
        v-ripple
      >
        <v-card-title :style="smallSizeMe">{{article.label}}</v-card-title>
        <v-card-text style="padding-bottom:90px;" v-if="mobileDisplay">{{article.short_description}}</v-card-text>
      </div>

      <v-card-actions
        style="position:absolute; bottom:0; left:0; background:white; width:100%;"
        v-if="mobileDisplay"
      >
        <v-row style class="ma-3" :to="'/user/'+article.author.id">
          <v-avatar size="36">
            <img :src="article.author.avatar" :alt="article.author.name" />
          </v-avatar>
          <p class="ma-2" style="margin-top:0 !important; line-height:17px;">
            <span class="smallDate">{{article.publication}}</span>
            <br />
            {{article.author.name}}
          </p>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<script>
import Alphabet from "@/components/Articles/alphabet.vue";

export default {
  name: "ArticlesComments",
  components: { Alphabet },
  props: ["article", "index", "hover"],
  computed: {
    imageHeight: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "30vh";
          case "sm":
            return "40vh";
          case "md":
            return "40vh";
          case "lg":
            return "30vh";
          case "xl":
            return "30vh";
          default:
            return "30vh";
        }
      },
    },
    mobileDisplay: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return false;
          case "md":
            return false;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
    smallSizeMe: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "font-size:16px; line-height:20px;";
          case "sm":
            return "font-size:16px; line-height:20px;";
          case "md":
            return "";
          case "lg":
            return "";
          case "xl":
            return "";
          default:
            return "";
        }
      },
    },
  },
  methods: {
    saveComment() {
      this.$emit("something", "hello world");
    },
    navigate(url) {
      this.$router.push({ path: url });
    },
  },
  mounted() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {},
  },
  data() {
    return {
      delay: 800,
    };
  },
};
</script>