<template>
  <div class="bige3d" style="padding: 10px; color: black">
    <v-row style="display: flex">
      <div style="margin: 10px">
        <v-img
          :src="NewsletterIcon"
          contain
          style="padding: 0px; margin: 0; max-height: 120px; min-width: 100%"
        ></v-img>
      </div>
      <div style="flex: 1; padding: 10px; padding-right: 25px">
        <h3>Recevoir la Bige News Semestrielle</h3>
        <p class="hidden-xs-only">
          Bige ne pollue pas ta boîte mail ! Tu peux recevoir des nouvelles 2
          fois par ans pas plus…
          <br />
          <a
            href="http://localhost:8080/feeds/petit-point-sur-les-newsletter"
            title="le point sur les newsletter et les emails"
            >En savoir plus</a
          >
        </p>
      </div>
    </v-row>
    <v-row class elevation="0">
      <v-col style="display: inline-flex; padding-left: 6%; padding-right: 6%">
        <v-text-field
          label="Entre ton Email ici et maintenant !"
          v-model="emailNews"
          style="flex: 1 1 auto"
        ></v-text-field>
        <v-btn color="primary" large @click="sendNews">Valider</v-btn>
        <!-- <v-img :src="IconSend" height="48" width="48" contain style="flex: 0 0 auto" /> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import newsletterIcon from "@/assets/images/icons/bige-icon-newsletter.svg";
import iconSend from "@/assets/images/icons/bige-icon-check.svg";

export default {
  data() {
    return {
      emailNews: "",
      NewsletterIcon: newsletterIcon,
      IconSend: iconSend,
    };
  },
  methods: {
    sendNews() {
      alert(
        "mais non je n'envoi pas de newsletter... de toute façon tu ne la liras jamais, en tout cas je ne lirais jamais ta newsletter..."
      );
    },
  },
};
</script>