<template>
  <v-hover v-slot:default="{ hover }" v-if="article">
    <v-card class="item-card" :elevation="hover ? 10 : 2" flat style="max-width:100%; width:100%;">
      <v-row style="min-height:100%;  max-width:100%; width:100%;" :hover="hover">
        <v-col
          style="padding-top: 0; padding-bottom: 0; margin-right:0; padding-right:0;min-width:auto !important;  min-height:100%;"
          cols="3"
        >
          <div class="notpublicated" v-if="!article.publicated">Non publié</div>
          <v-img
            :src="article.picture"
            link
            v-on:click="navigate('/feeds/'+article.url)"
            :data-id="index"
            :open-delay="delay"
            v-ripple
            :aspect-ratio="3/4"
            style="min-height:100%;"
            v-if="article.picture.indexOf('http') !== -1"
          />
          <div
            link
            v-on:click="navigate('/feeds/'+article.url)"
            v-ripple
            v-if="article.picture.indexOf('http') === -1"
            style="height:100%;"
          >
            <Alphabet
              :message="article.picture"
              :ratio="4/3"
              style="width:100%; height:100%; overflow:hidden; background-color:#e9ebf7;"
            ></Alphabet>
          </div>
        </v-col>
        <v-col
          cols="8"
          :style="'position:relative; margin:0; padding:0; min-width:auto !important;'+((article.picture.indexOf('http') !== -1)?'':'margin-left:10px;')"
        >
          <div
            link
            v-on:click="navigate('/feeds/'+article.url)"
            :data-id="index"
            :open-delay="delay"
            v-ripple
            style="max-width:100%;"
          >
            <v-card-title :style="smallSizeMe">{{article.label}}</v-card-title>
            <v-card-text
              style="padding-bottom:60px;"
              v-if="mobileDisplay"
            >{{article.short_description.substr(0,110) + ' ...'}}</v-card-text>
          </div>

          <v-card-actions
            :style="'position:absolute; bottom:0; left:0; background:white; '+((article.picture.indexOf('http') !== -1)?'width:100%;':'margin-left:10px;width:95%;')"
            v-if="mobileDisplay"
          >
            <v-row style class="ma-3" :to="'/user/'+article.author.id">
              <v-avatar size="36">
                <img :src="article.author.avatar" :alt="article.author.name" />
              </v-avatar>
              <p class="ma-2" style="margin-top:0 !important; line-height:17px;">
                <span class="smallDate">{{article.publication}}</span>
                <br />
                {{article.author.name}}
              </p>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>
<script>
import Alphabet from "@/components/Articles/alphabet.vue";

export default {
  name: "ArticlesComments",
  components: { Alphabet },
  props: ["article", "index", "hover"],
  computed: {
    imageHeight: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "40vh";
          case "sm":
            return "50vh";
          case "md":
            return "50vh";
          case "lg":
            return "50vh";
          case "xl":
            return "50vh";
          default:
            return "50vh";
        }
      }
    },
    mobileDisplay: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return false;
          case "md":
            return false;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      }
    },
    smallSizeMe: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "font-size:16px; line-height:20px;";
          case "sm":
            return "font-size:16px; line-height:20px;";
          case "md":
            return "font-size:16px; line-height:20px;";
          case "lg":
            return "";
          case "xl":
            return "";
          default:
            return "";
        }
      }
    }
  },
  methods: {
    saveComment() {
      this.$emit("something", "hello world");
    },
    navigate(url) {
      this.$router.push({ path: url });
    }
  },
  mounted() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {}
  },
  data() {
    return {
      delay: 800
    };
  }
};
</script>