<template>
  <div fluid style class="feeds">
    <!-- fixed-tabs="true" -->
    <div class="articles_list_tabs" style="position: fixed; z-index: 4">
      <div class="tab_nav_container">
        <v-tabs style="width: 100%; max-width: 980px" :fixed-tabs="fixedTab">
          <v-tab to="/feeds">Voir tout</v-tab>
          <v-tab to="/category/technology">R&D</v-tab>
          <v-tab to="/category/uiux">UI/UX</v-tab>
          <v-tab to="/category/integration">intégration</v-tab>
          <v-tab to="/category/divers">Divers</v-tab>
        </v-tabs>
      </div>
    </div>
    <searchBar></searchBar>

    <v-container style="max-width:1440px z-index:9;" v-if="articlesList">
      <v-fade-transition mode="out-in">
        <div>
          <v-row class="bige-content" style="padding: 0px; padding-top: 0">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              style="margin: 0; padding: 0"
            >
              <v-row style="margin: 0; padding: 0; height: 100%">
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  v-for="n in 2"
                  :key="'f' + n"
                  :style="
                    'margin: 0; padding: 10px; height:' + sameHeight + ';'
                  "
                >
                  <VerticalCard
                    :article="articlesList[n - 1]"
                    :index="n - 1"
                  ></VerticalCard>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              style="margin: 0; padding: 0"
            >
              <v-row
                :style="'margin: 0; padding: 0; height:' + sameHeight + ';'"
              >
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-for="n in 3"
                  :key="'s' + n"
                  style="margin: 0; padding: 10px"
                >
                  <HorizontalCard
                    :article="articlesList[n + 1]"
                    :index="n + 1"
                  ></HorizontalCard>
                  <!-- horizontal SM col {{n+2}} -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="bige-content" style="padding: 0px; padding-top: 0">
            <v-col
              v-for="n in 2"
              :key="'t' + n"
              :style="'margin: 0; padding: 10px; height:' + sameHeight + ';'"
            >
              <HorizontalCard
                :article="articlesList[n + 4]"
                :index="n + 4"
              ></HorizontalCard>
              <!-- block 2 column {{n+5}} -->
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </v-container>
    <v-parallax
      dark
      src="https://firebasestorage.googleapis.com/v0/b/bige-start.appspot.com/o/newsletter_illus.svg?alt=media&token=cfe92947-ed0e-4467-a523-cc16d3813253"
      v-if="articlesList.length > 4"
    >
      <NewsletterBloc></NewsletterBloc>
    </v-parallax>

    <v-container style="max-width: 1440px" v-if="articlesList">
      <v-fade-transition mode="out-in">
        <v-row
          class="bige-content"
          :style="'padding:0px; padding-top:0; height:' + sameHeight + ';'"
        >
          <v-col
            cols="12"
            xs="12"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            v-for="n in 4"
            :key="'u' + n"
          >
            <VerticalCard
              v-if="articlesList[n + 6]"
              :article="articlesList[n + 6]"
              :index="n"
            ></VerticalCard>
            <!-- vertical col {{n+7}} -->
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>

    <!-- <v-parallax
      dark
      src="https://firebasestorage.googleapis.com/v0/b/bige-start.appspot.com/o/notification_illus.svg?alt=media&token=a60396a7-c5ed-49f5-87f0-645b97a9ebc6"
      v-if="articlesList.length > 11"
    >
      <NotificationBloc></NotificationBloc>
    </v-parallax> -->

    <v-container style="max-width: 1440px">
      <v-fade-transition mode="out-in">
        <v-row class="bige-content" style="padding: 30px; padding-top: 0">
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="3"
            lg="3"
            xl="2"
            v-for="(article, index) in articlesList.slice(
              11,
              articlesList.length
            )"
            :key="article.id"
          >
            <VerticalCard :article="article" :index="index"></VerticalCard>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>

    <v-btn
      class="ma-2"
      large
      fab
      bottom
      right
      fixed
      color="primary"
      to="/write"
      v-if="
        $store.state.userProfile &&
        $store.state.userProfile.scopes.includes('writter')
      "
    >
      <v-icon>{{ pencil }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
// @ is an alias to /src
import customArray from "@/helpers/custom-array";
import iconSearch from "@/assets/images/icons/bige-icon-search.svg";
import VerticalCard from "../components/Articles/verticalCard";
import HorizontalCard from "../components/Articles/horizontalCard";
import NewsletterBloc from "../components/UI/NewsLetterBloc";
// import NotificationBloc from "../components/UI/NotificationBloc";
import searchBar from "../components/UI/SearchBar";
// import _ from "lodhash";
import { mdiPencil } from "@mdi/js";
export default {
  name: "Home",
  components: {
    VerticalCard,
    HorizontalCard,
    NewsletterBloc,
    // NotificationBloc,
    searchBar,
  },
  computed: {
    sameHeight: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "auto";
          case "sm":
            return "auto";
          case "md":
            return "100%";
          case "lg":
            return "100%";
          case "xl":
            return "100%";
          default:
            return "auto";
        }
      },
    },
    fixedTab: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
    articles: {
      get: function () {
        return this.$store.state.articles;
      },
      set: function () {},
    },
    articlesList: {
      get: function () {
        return customArray.where(
          this.$store.state.articles,
          this.$route.params,
          "all"
        );
      },
      set: function () {},
    },
  },
  data() {
    return {
      filter: "",
      iconSearch: iconSearch,
      pencil: mdiPencil,
      lorem: `Lorem ipsum dolor sit amet, mel at clita quando. Te sit oratio vituperatoribus, nam ad ipsum posidonium mediocritatem, explicari dissentiunt cu mea. Repudiare disputationi vim in, mollis iriure nec cu, alienum argumentum ius ad. Pri eu justo aeque torquatos.`,
    };
  },
  watch: {
    filter() {
      this.$store.commit("searchArticles", this.filter);
    },
    articlesList() {
      if (this.filter !== "") {
        return this.articles.filter(
          function (item) {
            let ok = 0;
            if (item.label.indexOf(this.filter) > -1) ok++;
            if (item.short_desciption.indexOf(this.filter) > -1) ok++;
            if (item.intro.indexOf(this.filter) > -1) ok++;
            if (item.markdown.indexOf(this.filter) > -1) ok++;
            if (item.tags.include(this.filter)) ok++;
            if (ok > 0) return true;
          }.bind(this)
        );
        // customArray.where(this.articles, this.$route.params, "all");
      } else {
        return customArray.where(this.articles, this.$route.params, "all");
      }
    },
  },
  methods: {
    navigate(url) {
      this.$router.push({ path: url });
    },
    // eslint-disable-next-line no-unused-vars
    onImgError: function (err) {
      this.failed_image = true;
    },
    searchHandler: function () {},
  },
  mounted() {
    /* if (typeof this.$route.params !== "undefined") {
      this.articles = customArray.where(
        this.articles,
        this.$route.params,
        "all"
      );
    } */
  },
};
</script>
